import axios from "axios";

import type { UserRole } from "@/shared.types";
import type { ServiceResponse } from "./api.types";

export interface LoginResponse {
  accessToken: string;
  role: UserRole;
}

export interface ResetPasswordData {
  password: string;
  password_confirmation: string;
  email: string;
  token: string;
}

export const login = async (data: {
  email: string;
  password: string;
}): Promise<LoginResponse> => {
  const response = await axios.post<ServiceResponse<LoginResponse>>(
    "/api/users/login",
    {
      ...data,
    },
  );
  return response.data.data;
};

export const resetPatientPassword = async (data: ResetPasswordData) => {
  const resp = await axios.post<ServiceResponse<null>>(
    `/api/patients/reset-password`,
    data,
  );
  return resp.data.data;
};

export const resetWebUserPassword = async (data: ResetPasswordData) => {
  const resp = await axios.post<ServiceResponse<null>>(
    `/api/users/reset-password`,
    data,
  );
  return resp.data.data;
};
