import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import type { Location } from "react-router-dom";

import { Layout } from "@/layout";
import { NotFound, Users } from "@/screens";
import { ForgotPassword } from "@/screens/ForgotPassword";
import { Login } from "@/screens/Login";
import { Participants } from "@/screens/participants/Participants";
import { EmailValidated } from "@/screens/Patient/EmailValidated";
import { PatientResetPassword } from "@/screens/Patient/ResetPassword";
import { ResetPasswordSuccess } from "@/screens/Patient/ResetPasswordSuccess";
import { ResearchStudiesScreen } from "@/screens/Research/ResearchStudiesScreen";
import { ActivateAccount } from "@/screens/User/ActivateAccount";
import { WebUserResetPassword } from "@/screens/User/ResetPassword";
import { ModalRouter } from "./ModalRouter";
import { ProtectedRoute } from "./ProtectedRoute";
import { ROUTES } from "./routes";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  return (
    <>
      {/* PUBLIC ONLY ROUTES */}
      <Routes location={previousLocation ?? location}>
        <Route
          element={<PatientResetPassword />}
          path={ROUTES.patientResetPassword}
        />
        <Route
          element={<ResetPasswordSuccess />}
          path={ROUTES.patientResetPasswordSuccess}
        />
        <Route element={<EmailValidated />} path={ROUTES.verifyEmail} />
        <Route element={<WebUserResetPassword />} path={ROUTES.resetPassword} />
        <Route element={<ActivateAccount />} path={ROUTES.activateAccount} />

        <Route element={<ProtectedRoute expected="loggedOut" />}>
          <Route element={<Login />} path={ROUTES.login} />
          <Route element={<ForgotPassword />} path={ROUTES.forgotPassword} />
        </Route>

        {/* PRIVATE ONLY ROUTES */}
        <Route element={<ProtectedRoute expected={["admin", "researcher"]} />}>
          <Route element={<Layout />}>
            <Route element={<Participants />} path={ROUTES.participants} />
            <Route
              element={<ResearchStudiesScreen />}
              path={ROUTES.researchStudies}
            />
            <Route
              element={<Navigate to={ROUTES.participants} />}
              path={ROUTES.base}
            />

            <Route path={ROUTES.notFound} element={<NotFound />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute expected="admin" />}>
          <Route element={<Layout />}>
            <Route element={<Users />} path={ROUTES.users} />
          </Route>
        </Route>
      </Routes>

      {/* MODALS ROUTES */}
      <Routes>
        <Route
          path="*"
          element={<ModalRouter showModal={!!previousLocation} />}
        />
      </Routes>
    </>
  );
};
