import type { SVGProps } from "@/shared.types";

export const NavbarLeftAsset = ({ className, ...props }: SVGProps) => {
  return (
    <svg
      width="236"
      height="141"
      className={className}
      viewBox="0 0 236 141"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2643_1090)">
        <path
          d="M-23.9498 36.0011C-23.9498 36.0011 21.3883 32.6424 49.7658 26.9084C83.0444 20.1838 132.957 2.1183 132.957 2.1183C98.4132 26.013 80.3955 41.2305 46.1347 64.9721M46.1347 64.9721C45.8659 65.1585 45.5963 65.3451 45.3254 65.5324M46.1347 64.9721L45.3254 65.5324M221.366 121.961C221.366 121.961 149.22 149.308 100.306 164.791C51.392 180.273 -29.1405 201.253 -29.1405 201.253L187.023 67.3417C187.023 67.3417 118.255 91.3758 73.1898 102.51C28.1249 113.644 -41.763 124.053 -41.763 124.053C-7.56267 100.396 11.0985 89.2075 45.3254 65.5324"
          stroke="#370880"
          strokeWidth="50.3742"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2643_1090">
          <rect
            width="272.393"
            height="222"
            fill="white"
            transform="translate(-103 45.7378) rotate(-23.5278)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
