import { NavbarLeftAsset } from "./NavbarLeftAsset";
import { NavbarRightAsset } from "./NavbarRightAsset";

export const Navbar = () => {
  return (
    <div className="flex h-28 justify-between overflow-hidden bg-violet-900">
      <NavbarLeftAsset />
      <NavbarRightAsset />
    </div>
  );
};
