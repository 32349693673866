import { twMerge } from "tailwind-merge";

export const PaginationButton = ({
  onClick,
  disabled,
  children,
  className,
}: {
  onClick: () => void;
  disabled: boolean;
  children: React.ReactNode;
  className?: string;
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={twMerge(
      "cursor-pointer bg-white leading-tight text-gray-500 hover:text-gray-700",
      className,
    )}
  >
    {children}
  </button>
);
