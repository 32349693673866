import { z } from "zod";

import { ACCEPTED_IMAGE_TYPES, MAX_UPLOAD_SIZE } from "./shared.constants";

export const fileSchema = z
  .instanceof(File)
  .refine((file) => {
    return !file || file.size <= MAX_UPLOAD_SIZE;
  }, "File size must be less than 10MB")
  .refine((file) => {
    return !file?.type || ACCEPTED_IMAGE_TYPES.includes(file.type);
  }, "File must be a PDF");
