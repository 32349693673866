import type { ColumnDef, Table } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";

import { Loading } from "@/ui/common/Loading";
import { TableBody, TableCell, TableRow } from "@/ui/primitives/Table";
import { tw } from "@/utils";

interface TableBodyRendererProps<T> {
  isLoading: boolean;
  table: Table<T>;
  columns: ColumnDef<T>[];
  noDataMessage: string;
}

const TableBodyRenderer = <T,>({
  isLoading,
  table,
  columns,
  noDataMessage,
}: TableBodyRendererProps<T>) => {
  return (
    <TableBody>
      {isLoading ? (
        <tr>
          <td colSpan={columns.length}>
            <Loading />
          </td>
        </tr>
      ) : table.getRowModel().rows?.length && !isLoading ? (
        table.getRowModel().rows.map((row) => (
          <TableRow
            key={row.id}
            data-state={row.getIsSelected() && "selected"}
            className={tw("even:bg-[#fafafb]")}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={columns.length} className="text-center">
            {noDataMessage}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default TableBodyRenderer;
