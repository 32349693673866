interface ContentHeaderProps {
  title: string;
  buttons: React.ReactNode;
}

export const ContentHeader = ({ title, buttons }: ContentHeaderProps) => {
  return (
    <div className="flex flex-row justify-between pb-6">
      <h1 className="font-epilogue text-4xl font-medium text-violet-950">
        {title}
      </h1>
      <div className="flex flex-row gap-2">{buttons}</div>
    </div>
  );
};
