import { icons } from "../Icons";
import { Ellipsis } from "./Ellipsis";
import { PageButton } from "./PageButton";
import { PaginationButton } from "./PaginationButton";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const SURROUNDING_PAGE_RANGE = 2;
const FIRST_PAGE = 1;
const PREVIOUS_PAGE_OFFSET = -1;
const NEXT_PAGE_OFFSET = 1;

export const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
}: PaginationProps) => {
  const { ChevronLeftIcon, ChevronRightIcon } = icons;

  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  const shouldDisplayPage = (pageNumber: number) => {
    const isLimitPage = pageNumber === FIRST_PAGE || pageNumber === totalPages;
    const isSurroundingCurrentPage =
      pageNumber > currentPage - SURROUNDING_PAGE_RANGE &&
      pageNumber < currentPage + SURROUNDING_PAGE_RANGE;
    return isLimitPage || isSurroundingCurrentPage;
  };

  return (
    <div className="flex items-center justify-end">
      {currentPage !== 1 && (
        <PaginationButton
          onClick={() => handlePageChange(currentPage + PREVIOUS_PAGE_OFFSET)}
          disabled={currentPage === FIRST_PAGE}
          className="mr-3"
        >
          <div className="flex flex-row items-center">
            <ChevronLeftIcon className="mx-2 h-4 w-4" />
            <span>Previous</span>
          </div>
        </PaginationButton>
      )}

      <ul className="flex items-center gap-1 text-gray-500">
        {Array.from({ length: totalPages }, (_, idx) => {
          const pageNumber = idx + 1;
          if (
            (pageNumber === currentPage - SURROUNDING_PAGE_RANGE ||
              pageNumber === currentPage + SURROUNDING_PAGE_RANGE) &&
            !shouldDisplayPage(pageNumber)
          ) {
            return (
              <div key={pageNumber}>
                <Ellipsis />
              </div>
            );
          }
          return shouldDisplayPage(pageNumber) ? (
            <PageButton
              key={pageNumber}
              pageNumber={pageNumber}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          ) : null;
        })}
      </ul>

      {currentPage !== totalPages && (
        <PaginationButton
          onClick={() => handlePageChange(currentPage + NEXT_PAGE_OFFSET)}
          disabled={currentPage === totalPages}
          className="ml-3"
        >
          <div className="flex flex-row items-center">
            <span>Next</span>
            <ChevronRightIcon className="mx-2 h-4 w-4" />
          </div>
        </PaginationButton>
      )}
    </div>
  );
};
