import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useResearchStudies } from "@/hooks/useResearchStudies";
import { Button, Modal, useToastStore } from "@/ui";
import Input from "@/ui/form/Input";
import { handleAxiosFieldErrors } from "@/utils/handleAxiosFieldErrors";

interface NewResearchStudyModalProps {
  show: boolean;
  onClose: () => void;
}

export const studySchema = z.object({
  studyName: z
    .string()
    .min(3, { message: "Name should be at least 3 characters long" }),
  researcherEmail: z.string().email(),
});

export type NewStudyFormValues = z.infer<typeof studySchema>;

export const NewResearchStudyModal = ({
  show,
  onClose,
}: NewResearchStudyModalProps) => {
  const pushToast = useToastStore((state) => state.pushToast);

  const {
    formState: { errors },
    register,
    setError,
    resetField,
    handleSubmit,
  } = useForm<NewStudyFormValues>({
    resolver: zodResolver(studySchema),
  });

  const { mutate: createStudyMutation, isPending: isLoading } =
    useResearchStudies().createResearchStudyMutation;

  return (
    <Modal
      title="Create new research study"
      description={
        <span className="flex flex-col gap-5 text-center">
          Please provide the details for the new research study and assign a
          researcher to manage it.
        </span>
      }
      onClose={onClose}
      show={show}
    >
      <form
        onSubmit={(e) => {
          void handleSubmit((data) => {
            createStudyMutation(data, {
              onSuccess: (res) => {
                void pushToast({
                  type: "success",
                  title: "Creation Success",
                  message: `Research Study "${res?.name}" successfully created!`,
                });
                resetField("studyName");
                resetField("researcherEmail");
                onClose();
              },
              onError: (err) => {
                handleAxiosFieldErrors(err, setError);
              },
            });
          })(e);
        }}
      >
        <div className="flex flex-col gap-6 px-6 pb-3">
          <Input
            label="Study Name*"
            type="text"
            id="study_name"
            className="w-full p-3 text-sm"
            placeholder="Input name here"
            error={errors.studyName?.message}
            {...register("studyName")}
          />

          <Input
            label="Researcher email*"
            type="text"
            id="researcher_email"
            className="w-full p-3 text-sm"
            placeholder="Input email here"
            error={errors.researcherEmail?.message}
            {...register("researcherEmail")}
          />
        </div>
        <div className="flex flex-row justify-center gap-2 px-6 pb-6">
          <Button
            className="flex-1 border border-violet-900 px-2 py-2  font-semibold text-violet-900"
            variant="outline"
            isLoading={false}
            onClick={onClose}
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="flex-1 px-2 py-0"
            variant="primary"
            isLoading={isLoading}
            disabled={isLoading}
            type="submit"
          >
            Create study
          </Button>
        </div>
      </form>
    </Modal>
  );
};
