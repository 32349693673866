import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import type { Study } from "@/api/api.types";
import { useResearchStudies } from "@/hooks/useResearchStudies";
import { NewResearchStudyModal } from "@/modals/Research/NewResearchStudyModal";
import { UploadResearchStudyConsentModal } from "@/modals/Research/UploadResearchStudyConsentModal";
import { useUserStore } from "@/stores";
import { Button } from "@/ui/common/Button";
import { tw } from "@/utils";
import { ContentHeader } from "../participants/ContentHeader";
import ResearchStudiesTable from "./ResearchStudiesTable";

const DEFAULT_PAGE = 1;

export const ResearchStudiesScreen = () => {
  const [newResearchStudyModalOpen, setNewResearchStudyModalOpen] =
    useState(false);
  const [params, setParams] = useSearchParams();

  const { isAdmin } = useUserStore();

  const currentPage =
    Number(params.get("page")) > 0 ? Number(params.get("page")) : DEFAULT_PAGE;

  const useResearchStudiesHook = useResearchStudies();

  const { data, isLoading } = isAdmin()
    ? useResearchStudiesHook.useGetResearchStudiesQuery(
        params,
        true,
        currentPage,
      )
    : useResearchStudiesHook.useGetMyResearchStudyQuery();

  const studies = data?.data;
  const pagination = data?.pagination;

  const adminResearchTable = (studies?: Study[]) => (
    <div className="flex h-full flex-1 flex-col gap-9">
      <ContentHeader
        title="Research Studies"
        buttons={
          <Button
            className="h-10 w-44 px-2 py-0"
            variant="primary"
            isLoading={false}
            onClick={() => setNewResearchStudyModalOpen(true)}
          >
            New research study
          </Button>
        }
      />
      <ResearchStudiesTable
        isLoading={isLoading}
        researchStudies={studies ?? []}
        isAdmin
        pagination={{
          pageIndex: currentPage,
          pageSize: pagination?.perPage ?? 0,
          totalPages: pagination?.totalPages ?? 0,
          onPageChange: (page: number) =>
            setParams({ ...params, page: String(page) }),
        }}
      />
      <NewResearchStudyModal
        show={newResearchStudyModalOpen}
        onClose={() => {
          setNewResearchStudyModalOpen(false);
        }}
      />
    </div>
  );

  const researcherResearchTable = (study: Study) => {
    const studyHasConsent = study?.consentStatus === "uploaded";
    return (
      <div className="flex h-full flex-1 flex-col">
        {!isLoading && (
          <ContentHeader
            title={
              study.name.length > 60
                ? study.name.substring(0, 60) + "..."
                : study.name
            }
            buttons={
              <>
                <Button
                  className={tw(
                    "h-10 w-40 px-2 py-0",
                    studyHasConsent && "border-violet-900 text-violet-900",
                  )}
                  variant={studyHasConsent ? "outline" : "primary"}
                  isLoading={false}
                  onClick={() => setNewResearchStudyModalOpen(true)}
                >
                  {studyHasConsent ? "Edit consent" : "Upload consent"}
                </Button>
                {study?.consentStatus === "uploaded" && (
                  <a
                    href={study.consentUrl}
                    target="_blank"
                    className="flex h-10 w-40 items-center justify-center gap-2 rounded-md border border-transparent bg-violet-900 px-2 py-0 font-semibold text-white hover:bg-violet-700"
                    rel="noreferrer"
                  >
                    View consent
                  </a>
                )}
              </>
            }
          />
        )}
        <ResearchStudiesTable
          isLoading={isLoading}
          researchStudies={study ? [study] : []}
          pagination={{
            pageIndex: currentPage,
            pageSize: pagination?.perPage ?? 0,
            totalPages: pagination?.totalPages ?? 0,
            onPageChange: (page: number) =>
              setParams({ ...params, page: String(page) }),
          }}
        />
        <UploadResearchStudyConsentModal
          show={newResearchStudyModalOpen}
          onClose={() => {
            setNewResearchStudyModalOpen(false);
          }}
        />
      </div>
    );
  };

  return isAdmin()
    ? adminResearchTable(studies as Study[])
    : researcherResearchTable(studies as Study);
};
