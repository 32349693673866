import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { UseFormSetError } from "react-hook-form";

import {
  deleteParticipantsFromStudies,
  getParticipants,
  inviteParticipant,
  sendParticipantsScore,
} from "@/api/participants";
import { errorToast, useToastStore } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";

const PARTICIPANTS_PAGE_SIZE = 8;

export const PARTICIPANTS_QUERY_KEYS = {
  getActiveParticipants: "getActiveParticipants",
  getPendingParticipants: "getPendingParticipants",
};

export const useParticipants = () => {
  const { pushToast } = useToastStore();
  const queryClient = useQueryClient();

  const useGetActiveParticipantsQuery = (
    currentPage: number,
    params: URLSearchParams,
  ) =>
    useQuery({
      queryFn: () =>
        getParticipants(currentPage, PARTICIPANTS_PAGE_SIZE, "active"),
      queryKey: [
        PARTICIPANTS_QUERY_KEYS.getActiveParticipants,
        Object.fromEntries(params),
      ],
    });

  const useGetPendingParticipantsQuery = (
    currentPage: number,
    params: URLSearchParams,
  ) =>
    useQuery({
      queryFn: () =>
        getParticipants(currentPage, PARTICIPANTS_PAGE_SIZE, "pending"),
      queryKey: [
        PARTICIPANTS_QUERY_KEYS.getPendingParticipants,
        Object.fromEntries(params),
      ],
    });

  const useInviteParticipantMutation = (
    onSuccess: () => void,
    setError: UseFormSetError<{
      patientEmail: string;
      researchStudyId: number;
    }>,
  ) =>
    useMutation({
      mutationFn: inviteParticipant.mutation,
      onSuccess: async () => {
        void pushToast({
          type: "success",
          title: "Success",
          message: `Participant was successfully invited!`,
        });
        await queryClient.invalidateQueries({
          queryKey: [PARTICIPANTS_QUERY_KEYS.getPendingParticipants],
        });
        onSuccess();
      },
      onError: (err) => {
        errorToast(err);
        handleAxiosFieldErrors(err, setError);
      },
    });

  const useSendParticipantsScoreMutation = (onSuccess: () => void) =>
    useMutation({
      mutationFn: sendParticipantsScore.mutation,
      onSuccess: () => {
        void pushToast({
          type: "success",
          title: "Success",
          message: `You will receive the report by email soon!`,
        });
        onSuccess();
      },
      onError: () => {
        void pushToast({
          type: "error",
          title: "Error",
          message: `An error occurred. Please try again later.`,
        });
      },
    });

  const useDeleteParticipantsFromStudiesMutation = (onSuccess: () => void) =>
    useMutation({
      mutationFn: deleteParticipantsFromStudies.mutation,
      onSuccess: async () => {
        void pushToast({
          type: "success",
          title: "Success",
          message: "Participants have been removed from their studies",
        });
        await queryClient.invalidateQueries({
          queryKey: [PARTICIPANTS_QUERY_KEYS.getActiveParticipants],
        });
        onSuccess();
      },
      onError: () => {
        void pushToast({
          type: "error",
          title: "Error",
          message: `An error occurred. Please try again later.`,
        });
      },
    });

  return {
    useGetActiveParticipantsQuery,
    useGetPendingParticipantsQuery,
    useInviteParticipantMutation,
    useSendParticipantsScoreMutation,
    useDeleteParticipantsFromStudiesMutation,
  };
};
