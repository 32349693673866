import type { ReactElement, ReactNode } from "react";
import React, { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

import { tw } from "@/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;

  error?: string | boolean;
  icon?: ReactElement;
  inputRequired?: string;
  label?: string;
  labelClassName?: string;
  optional?: string;
  subLabel?: string;
  tooltip?: ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      containerClassName,
      error,
      icon,
      id,
      inputRequired,
      label,
      labelClassName,
      optional,
      placeholder,
      subLabel,
      tooltip,
      type = "text",
      ...props
    },
    ref,
  ) => {
    const [currentType, setCurrentType] = useState(type);

    const changeType = () => {
      if (currentType === "password") {
        setCurrentType("text");
      } else {
        setCurrentType("password");
      }
    };

    return (
      <div className={tw("flex flex-col gap-1", containerClassName)}>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-2">
            <label
              className={tw(
                "mb-2 block text-left text-sm font-semibold text-gray-800",
                labelClassName,
              )}
              htmlFor={id}
            >
              {label}
              {(optional ?? inputRequired) && (
                <span
                  className={tw(
                    "ml-2 text-sm italic",
                    optional && "text-neutral-100",
                    inputRequired && "text-error-600",
                  )}
                >
                  {optional ?? inputRequired}
                </span>
              )}
            </label>
          </div>
          <div className="mt-[1.5px]">{tooltip && tooltip}</div>
        </div>
        {subLabel && (
          <span className="mb-1 mt-1 text-left text-xs text-neutral-800">
            {subLabel}
          </span>
        )}
        <div className="relative">
          {!!icon && (
            <div className="absolute inset-y-0 left-3 flex items-center pr-3 text-base leading-5">
              {icon}
            </div>
          )}
          <input
            type={currentType}
            ref={ref}
            id={id}
            key={id}
            placeholder={placeholder}
            className={tw(
              "focus:ring-none focus:border-primary block h-12 w-full rounded-xl border border-gray-300 p-3 text-sm font-medium text-neutral-900 placeholder-gray-400 shadow-sm focus:border-opacity-60 focus:outline-none focus:ring-0",
              error && "border-error-600",
              className,
              !!icon && "pl-9",
              props.disabled && "bg-gray-50",
            )}
            aria-errormessage={`${id}_error`}
            {...props}
          />
          {type === "password" && !props.disabled && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-base leading-5">
              <button onClick={changeType} type="button">
                {currentType === "password" ? (
                  <EyeSlashIcon className="h-4 w-4 stroke-2 text-gray-500"></EyeSlashIcon>
                ) : (
                  <EyeIcon className="h-4 w-4 stroke-2 text-gray-500"></EyeIcon>
                )}
              </button>
            </div>
          )}
        </div>
        {error && (
          <span
            id={`${id}_error`}
            className="text-left text-sm font-medium text-error-600"
          >
            {error}
          </span>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";

export default Input;
