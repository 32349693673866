import type { ColumnDef } from "@tanstack/react-table";

import type { Study } from "@/api/api.types";
import { Chip } from "@/ui/common/Chip";
import { capitalize, tw } from "@/utils";
import { DeleteResearchStudyButton } from "./DeleteResearchStudyButton";

export const ResearchStudiesColumns: (
  isAdmin: boolean,
) => ColumnDef<Study>[] = (isAdmin: boolean) => {
  const columns: ColumnDef<Study>[] = isAdmin
    ? [
        {
          id: "studyName",
          accessorKey: "consentStatus",
          header: "Study name",
          cell: ({ row }: { row: { original: Study } }) => {
            return (
              <div className="flex items-center">
                <div
                  className={tw(
                    "mr-2 h-3 w-3 rounded-full",
                    row.original.consentStatus === "uploaded"
                      ? "bg-green-500"
                      : "bg-yellow-400",
                  )}
                ></div>
                {row.original.name}
              </div>
            );
          },
        },
        {
          id: "researcher_email",
          accessorKey: "researcherEmail",
          header: "Researcher Email",
        },
        {
          id: "consentUploaded",
          accessorKey: "consentStatus",
          header: "Consent Uploaded",
          cell: ({ row }: { row: { original: Study } }) => {
            return (
              <Chip
                className={tw(
                  "h-7 w-28 font-medium",
                  row.original.consentStatus === "uploaded"
                    ? "bg-teal-100 text-teal-800"
                    : "bg-yellow-100 text-yellow-800",
                )}
              >
                {capitalize(row.original.consentStatus) ?? "Pending"}
              </Chip>
            );
          },
        },
        {
          id: "dateCreated",
          accessorKey: "created_at",
          header: "Date Created",
          cell: ({ row }: { row: { original: Study } }) => {
            const date = new Date(row.original.createdAt);
            return date.toLocaleDateString("es-ES", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
              timeZone: "UTC",
            });
          },
        },
        {
          id: "participants",
          accessorKey: "participantsCount",
          header: "Participants associated",
        },
        {
          id: "deleteResearch",
          accessorKey: "deleteResearch",
          header: "",
          cell: ({ row }: { row: { original: Study } }) => {
            return (
              <DeleteResearchStudyButton
                researchId={row.original.id.toString()}
              />
            );
          },
        },
      ]
    : [
        {
          id: "researcherEmail",
          accessorKey: "researcherEmail",
          header: "Researcher email",
        },
        {
          id: "dateCreated",
          accessorKey: "createdAt",
          header: "Date created",
        },
        {
          id: "participantsAssociated",
          accessorKey: "participantsCount",
          header: "Participants associated",
        },
      ];

  return columns;
};
