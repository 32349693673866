import type { HeaderGroup } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";

import { TableHead, TableHeader, TableRow } from "@/ui/primitives/Table";

const TableHeaderRenderer = <T,>({
  headerGroups,
}: {
  headerGroups: HeaderGroup<T>[];
}) => (
  <TableHeader>
    {headerGroups.map((headerGroup) => (
      <TableRow key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <TableHead
            key={header.id}
            className="bg-white font-epilogue text-sm/5 font-semibold"
          >
            {header.isPlaceholder ? null : (
              <div className="flex items-center gap-2">
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </div>
            )}
          </TableHead>
        ))}
      </TableRow>
    ))}
  </TableHeader>
);

export default TableHeaderRenderer;
