import { tw } from "@/utils";

interface H1Props {
  children: React.ReactNode;
  className?: string;
}

const H1 = ({ children, className }: H1Props) => (
  <h1 className={tw("text-2xl font-semibold text-titleBlack", className)}>
    {children}
  </h1>
);

export default H1;
