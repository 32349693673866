export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

export const SIZE = {
  X_SMALL: "xs",
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
  X_LARGE: "xl",
} as const;

export const IMAGE_FILE_TYPES = {
  PDF: "application/pdf" as Blob["type"],
} as const;

export const ACCEPT_IMAGE_FILE = {
  [IMAGE_FILE_TYPES.PDF]: [],
};

export const ACCEPTED_IMAGE_TYPES: Blob["type"][] =
  Object.keys(ACCEPT_IMAGE_FILE);

export const MAX_UPLOAD_SIZE = 1024 * 1024 * 10; // 10MB
