import type { QueryClient } from "@tanstack/react-query";

import type { NewStudyFormValues } from "@/modals/Research/NewResearchStudyModal";
import type { UploadConsentFormValues } from "@/modals/Research/UploadResearchStudyConsentModal";
import type { IPaginated } from "@/models/IPaginated";
import type { ServiceResponse, Study } from "./api.types";
import { privateAPI } from "./axios";

export const getResearchStudiesQuery = async (
  page?: number,
  pageSize?: number,
) => {
  const response = await privateAPI.get<IPaginated<Study>>("/users/studies", {
    params: { page: page, pageSize: pageSize },
  });
  return response.data;
};

export const getMyResearchStudy = async () => {
  const response = await privateAPI.get<ServiceResponse<Study>>(
    "/users/studies/my-study",
  );
  return response.data;
};

export const postResearchStudy = async (researchStudy: Study) => {
  const response = await privateAPI.post<Study>(
    "/users/studies",
    researchStudy,
  );
  return response.data;
};

export const createStudy = {
  mutation: async (study: NewStudyFormValues) => {
    const response = await privateAPI.post<ServiceResponse<Study>>(
      "/users/studies",
      study,
    );
    return response.data.data;
  },

  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: ["getResearchStudies"] });
  },
};

export const deleteResearchStudy = {
  mutation: async (studyId: string) => {
    const response = await privateAPI.delete<ServiceResponse<Study>>(
      `/users/studies/${studyId}`,
    );
    return response.data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: ["getResearchStudies"] });
  },
};

export const uploadResearchStudyConsent = {
  mutation: async (consent: UploadConsentFormValues) => {
    const formData = new FormData();
    formData.append("consentFile", consent.consentFile);

    const response = await privateAPI.post<ServiceResponse<Study>>(
      `/users/studies/upload-consent`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } },
    );
    return response.data.data;
  },
};
