import { CheckCircleIcon } from "@heroicons/react/24/outline";

import { MainLayout } from "@/layout/MainLayout";
import H1 from "@/ui/form/H1";

export const EmailValidated = () => {
  return (
    <MainLayout>
      <div className="h-full w-full justify-center gap-48 pt-32 lg:flex lg:h-fit lg:flex-row lg:px-48">
        <div className="hidden xl:block">
          <img src="/images/reset-password-success-logo.png" alt="" />
        </div>

        <div className="flex h-full flex-col justify-between px-6 lg:w-96 lg:px-0">
          <div>
            <CheckCircleIcon className="mb-4 w-10 stroke-violet-800 stroke-[1.5px]"></CheckCircleIcon>
            <H1 className="pb-4 font-semibold">
              Your email has been validated!
            </H1>
            <div className="text-gray-500">
              We’re all set. Go to the app to log in and get started.
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
