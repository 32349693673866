import { twMerge } from "tailwind-merge";

export const PageButton = ({
  pageNumber,
  currentPage,
  onPageChange,
}: {
  pageNumber: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}) => (
  <li key={pageNumber}>
    <button
      aria-current={pageNumber === currentPage ? "page" : undefined}
      onClick={() => onPageChange(pageNumber)}
      className={twMerge(
        "border-2 border-transparent text-sm font-medium hover:text-violet-950",
        pageNumber === currentPage && "hover:b text-violet-950",
      )}
    >
      <div
        className={twMerge(
          "flex h-9 w-9 items-center justify-center rounded-md hover:bg-violet-50",
          pageNumber === currentPage &&
            "flex h-9 w-9 items-center justify-center rounded-md bg-violet-200",
        )}
      >
        {pageNumber}
      </div>
    </button>
  </li>
);
