import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { forgotPassword } from "@/api/forgotPassword";
import { AuthLayout } from "@/layout/AuthLayout";
import { ROUTES } from "@/router";
import { Button } from "@/ui";
import { errorToast } from "@/ui/common/Toast/errorToast";
import H1 from "@/ui/form/H1";
import Input from "@/ui/form/Input";

const forgotPasswordSchema = z.object({
  email: z.string().email(),
});

type ForgotPasswordForm = z.infer<typeof forgotPasswordSchema>;

const useForgotPassword = () => {
  return useMutation({
    mutationFn: forgotPassword,
  });
};

export const ForgotPassword = () => {
  const [checkEmail, setCheckEmail] = useState(false);
  const navigate = useNavigate();
  const { mutate: forgotPasswordAction } = useForgotPassword();
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
  } = useForm<ForgotPasswordForm>({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(forgotPasswordSchema),
  });
  const onSubmit: SubmitHandler<ForgotPasswordForm> = (data) => {
    forgotPasswordAction(data.email, {
      onSuccess: () => {
        setCheckEmail(true);
      },
      onError: (error) => {
        errorToast(error);
      },
    });
  };

  const renderCheckEmail = () => {
    return (
      <div className="flex flex-col gap-64">
        <div className="flex flex-col gap-4">
          <H1 className="font-epilogue">Check your email</H1>
          <p className="text-base text-subtitleGray">
            A link to reset your password has been sent to your email. Please
            check your inbox.
          </p>
        </div>
        <Button
          onClick={() => {
            navigate(ROUTES.login);
          }}
          className="w-full"
          type="submit"
          variant="primary"
        >
          Back to login
        </Button>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form className="flex flex-col gap-28" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-9">
          <div className="flex flex-col gap-4">
            <H1 className="font-epilogue">Forgot Password?</H1>
            <p className="text-base  text-subtitleGray">
              Don&apos;t worry, it happens to the best of us.
            </p>
          </div>

          <Input
            label="Email Address"
            type="text"
            error={errors?.email?.message}
            id="email_login"
            className="w-full p-3 text-sm"
            placeholder="Enter your email address"
            {...register("email", { required: true })}
          ></Input>
        </div>
        <div>
          <Button
            className="w-full"
            type="submit"
            variant="primary"
            disabled={!isDirty}
          >
            Continue
          </Button>
          <Button
            onClick={() => {
              navigate(ROUTES.login);
            }}
            className="w-full text-sm font-semibold text-gray-500"
            type="button"
            variant="tertiary"
          >
            Back to login
          </Button>
        </div>
      </form>
    );
  };

  return (
    <AuthLayout>{checkEmail ? renderCheckEmail() : renderForm()}</AuthLayout>
  );
};
