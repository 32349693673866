import * as heroIcons from "@heroicons/react/24/outline";
import * as outlineHeroIcons from "@heroicons/react/24/outline";

import type { SVGProps } from "@/shared.types";
import { tw } from "@/utils";

export const outlineIcons = {
  ...outlineHeroIcons,
};

export const icons = {
  ...heroIcons,

  Check: ({ className, ...props }: SVGProps) => (
    <svg
      viewBox="0 0 10 8"
      fill="currentColor"
      className={className}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.47334 0.806665C9.41136 0.74418 9.33763 0.694583 9.25639 0.660738C9.17515 0.626892 9.08802 0.609467 9.00001 0.609467C8.912 0.609467 8.82486 0.626892 8.74362 0.660738C8.66238 0.694583 8.58865 0.74418 8.52667 0.806665L3.56001 5.78L1.47334 3.68667C1.40899 3.62451 1.33303 3.57563 1.2498 3.54283C1.16656 3.51003 1.07768 3.49394 0.988222 3.49549C0.898768 3.49703 0.810495 3.51619 0.728443 3.55185C0.646391 3.58751 0.572166 3.63898 0.510007 3.70333C0.447848 3.76768 0.398972 3.84364 0.36617 3.92688C0.333367 4.01011 0.31728 4.099 0.318828 4.18845C0.320375 4.2779 0.339527 4.36618 0.375189 4.44823C0.410852 4.53028 0.462326 4.60451 0.526674 4.66667L3.08667 7.22667C3.14865 7.28915 3.22238 7.33875 3.30362 7.37259C3.38486 7.40644 3.472 7.42386 3.56001 7.42386C3.64802 7.42386 3.73515 7.40644 3.81639 7.37259C3.89763 7.33875 3.97137 7.28915 4.03334 7.22667L9.47334 1.78667C9.54101 1.72424 9.59502 1.64847 9.63195 1.56414C9.66889 1.4798 9.68796 1.38873 9.68796 1.29667C9.68796 1.2046 9.66889 1.11353 9.63195 1.02919C9.59502 0.944861 9.54101 0.869094 9.47334 0.806665Z" />
    </svg>
  ),

  Spinner: ({ className, ...props }: SVGProps) => (
    <div role="status">
      <svg
        aria-hidden="true"
        className={tw(
          "mr-2 h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600",
          className,
        )}
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  ),

  DragNDrop: ({ className, ...props }: SVGProps) => (
    <svg
      width="47"
      height="38"
      viewBox="0 0 47 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M31.5 27L23.5 19L15.5 27"
        stroke="#2E1065"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 19V37"
        stroke="#2E1065"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.28 31.78C42.2307 30.7165 43.7716 29.0337 44.6597 26.9972C45.5478 24.9607 45.7324 22.6864 45.1844 20.5334C44.6364 18.3803 43.387 16.471 41.6333 15.1069C39.8797 13.7427 37.7217 13.0014 35.5 13H32.98C32.3746 10.6585 31.2463 8.48464 29.6799 6.64195C28.1134 4.79927 26.1497 3.33567 23.9362 2.36118C21.7227 1.3867 19.3171 0.926686 16.9002 1.01573C14.4833 1.10478 12.1181 1.74057 9.98233 2.8753C7.84655 4.01003 5.9958 5.61417 4.56923 7.56713C3.14265 9.52009 2.17736 11.771 1.74594 14.1508C1.31452 16.5305 1.42819 18.977 2.07841 21.3065C2.72862 23.636 3.89847 25.7877 5.49998 27.6"
        stroke="#2E1065"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5 27L23.5 19L15.5 27"
        stroke="#2E1065"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),

  Participants: ({ className, ...props }: SVGProps) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M18.4 10.8002C18.4 12.7882 20.012 15.2002 22 15.2002C23.988 15.2002 25.6 12.7882 25.6 10.8002C25.6 8.8122 23.988 7.2002 22 7.2002C20.012 7.2002 18.4 8.8122 18.4 10.8002Z"
        strokeWidth="1.92"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M22.3999 23.1999L27.9999 23.1999V21.4703C27.9999 20.4919 27.4119 19.6071 26.5031 19.2455C25.4631 18.8311 23.9199 18.3999 21.9999 18.3999C20.0799 18.3999 18.5367 18.8311 17.4967 19.2455C17.0679 19.4167 16.7127 19.7015 16.4543 20.0599"
        strokeWidth="1.92"
        strokeMiterlimit="10"
      />
      <path
        d="M19.2 25.6002L4 25.6002L4 23.3482C4 22.1274 4.6888 21.0098 5.7864 20.4754C7.0672 19.8514 9.0216 19.2002 11.6 19.2002C14.1784 19.2002 16.1328 19.8514 17.4136 20.4754C18.5112 21.0098 19.2 22.1274 19.2 23.3482V25.6002Z"
        strokeWidth="1.92"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16 10.7999C16 13.2303 14.0304 15.9999 11.6 15.9999C9.16955 15.9999 7.19995 13.2303 7.19995 10.7999C7.19995 8.3695 9.16955 6.3999 11.6 6.3999C14.0304 6.3999 16 8.3695 16 10.7999Z"
        strokeWidth="1.92"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  ),
  Research: ({ className, color, ...props }: SVGProps) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M16.8 22.3999H12" strokeWidth="1.92" strokeMiterlimit="10" />
      <path
        d="M21.8808 19.0097L19.6616 15.4585"
        strokeWidth="1.92"
        strokeMiterlimit="10"
      />
      <path
        d="M13.8808 12.9902L11.8088 16.3054"
        strokeWidth="1.92"
        strokeMiterlimit="10"
      />
      <path
        d="M8 26.3999C10.2091 26.3999 12 24.609 12 22.3999C12 20.1908 10.2091 18.3999 8 18.3999C5.79086 18.3999 4 20.1908 4 22.3999C4 24.609 5.79086 26.3999 8 26.3999Z"
        strokeWidth="1.92"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M24 26.3999C26.2091 26.3999 28 24.609 28 22.3999C28 20.1908 26.2091 18.3999 24 18.3999C21.7909 18.3999 20 20.1908 20 22.3999C20 24.609 21.7909 26.3999 24 26.3999Z"
        strokeWidth="1.92"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16 13.6001C18.2091 13.6001 20 11.8092 20 9.6001C20 7.39096 18.2091 5.6001 16 5.6001C13.7909 5.6001 12 7.39096 12 9.6001C12 11.8092 13.7909 13.6001 16 13.6001Z"
        className={color}
        strokeWidth="1.92"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  ),
};

export const IconWrapper = ({
  size = "md",
  className,
  style,
  children,
}: {
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => (
  <div
    className={tw(
      "item-center flex flex-row",
      size === "sm" && "h-5 w-5",
      size === "md" && "h-6 w-6",
      size === "lg" && "h-7 w-7",
      size === "xl" && "h-10 w-10",
      className,
    )}
    style={style}
  >
    {children}
  </div>
);
