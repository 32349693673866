import React from "react";

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex h-screen  flex-row ">
      <div className="flex grow basis-[45%] items-center justify-center bg-violet-900">
        <img
          src="/images/goodhope-logo.svg"
          className="w-64"
          alt="This is GoodHope logo"
        />
      </div>
      <div className=" flex grow basis-[55%] items-center justify-center bg-gray-50">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">{children}</div>
      </div>
    </div>
  );
};
