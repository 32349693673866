import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";

import type { ResetPasswordData } from "@/api/login";
import { resetPatientPassword, resetWebUserPassword } from "@/api/login";
import { MainLayout } from "@/layout/MainLayout";
import { ROUTES } from "@/router";
import { PASSWORD_REGEX } from "@/shared.constants";
import { Button, errorToast } from "@/ui";
import H1 from "@/ui/form/H1";
import Input from "@/ui/form/Input";
import { handleAxiosFieldErrors } from "@/utils";

const schema = z
  .object({
    password: z
      .string()
      .min(1, "Error: Password is required")
      .regex(
        PASSWORD_REGEX,
        "Error: Password must be at least 8 characters long, contain at least one uppercase letter, and contain at least one digit.",
      ),
    password_confirmation: z
      .string()
      .min(1, "Error: Password confirmation is required"),
    email: z.string(),
    token: z.string(),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Error: Passwords don't match",
    path: ["password_confirmation"],
  });

interface ResetPasswordProps {
  resetRole: "patient" | "webUser" | "webUserActivation";
}

export const ResetPassword = ({ resetRole }: ResetPasswordProps) => {
  const navigate = useNavigate();

  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") ?? undefined;

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: { email, token },
  });

  const { status: patientStatus, mutate: setPatientPassword } = useMutation({
    mutationFn: (data: ResetPasswordData) => resetPatientPassword(data),
    onError: (error: Error) => errorToast(error),
    onSuccess: () => {
      navigate(ROUTES.patientResetPasswordSuccess + `?type=${resetRole}`);
    },
  });

  const { status: webUserStatus, mutate: setWebUserPassword } = useMutation({
    mutationFn: (data: ResetPasswordData) => resetWebUserPassword(data),
    onError: (error: Error) => {
      errorToast("The invitation has expired or is invalid.");
      handleAxiosFieldErrors(error, setError);
    },
    onSuccess: () => {
      navigate(ROUTES.patientResetPasswordSuccess + `?type=${resetRole}`);
    },
  });

  const isLoading = patientStatus === "pending" || webUserStatus === "pending";

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (data) => {
    if (resetRole === "patient") {
      setPatientPassword(data);
    } else if (resetRole === "webUser" || resetRole === "webUserActivation") {
      setWebUserPassword(data);
    }
  };

  return (
    <MainLayout>
      <div className="h-full w-full justify-center gap-48 pt-16 lg:flex lg:h-fit lg:flex-row lg:px-48 lg:pt-32">
        <div className="hidden xl:block">
          <img src="/images/reset-password-logo.svg" alt="" />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full flex-col justify-between px-6 lg:w-96 lg:px-0"
        >
          <div className=" pb-6">
            <H1 className="pb-9">
              {resetRole === "webUserActivation"
                ? "Set your password"
                : "Reset your password"}
            </H1>
            <div className="flex flex-col gap-6">
              <Input
                label="Password"
                type="password"
                error={errors?.password?.message}
                id="specialist_phone"
                className="w-full p-3 text-sm"
                placeholder="Enter your password"
                {...register("password")}
              />

              <Input
                label="Confirm password"
                type="password"
                error={errors?.password_confirmation?.message}
                id="password_confirmation"
                className="w-full p-3 text-sm"
                placeholder="Repeat your password"
                {...register("password_confirmation")}
              />
            </div>
          </div>

          <div className="flex flex-col gap-1 pb-6">
            <Button
              className="w-full"
              type="submit"
              variant="primary"
              isLoading={isLoading}
            >
              {resetRole === "webUserActivation"
                ? "Set password"
                : "Reset password"}
            </Button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};
