import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useResearchStudies } from "@/hooks/useResearchStudies";
import { ACCEPT_IMAGE_FILE } from "@/shared.constants";
import { fileSchema } from "@/shared.schemas";
import { Button, Checkbox, Modal, useToastStore } from "@/ui";
import { ControlledDropzone } from "@/ui/form/Dropzone";
import { handleAxiosFieldErrors } from "@/utils";

interface UploadResearchStudyConsentModalProps {
  show: boolean;
  onClose: () => void;
}

export const uploadConsentSchema = z.object({
  consentFile: fileSchema,
});

export type UploadConsentFormValues = z.infer<typeof uploadConsentSchema>;

export const UploadResearchStudyConsentModal = ({
  show,
  onClose,
}: UploadResearchStudyConsentModalProps) => {
  const { control, handleSubmit, setError, resetField } =
    useForm<UploadConsentFormValues>({
      resolver: zodResolver(uploadConsentSchema),
    });

  const pushToast = useToastStore((state) => state.pushToast);

  const { mutate: uploadStudyConsentMutation, isPending: isLoading } =
    useResearchStudies().uploadResearchStudyConsentMutation;

  const [checkedConsent, setCheckedConsent] = useState(false);

  const handleClose = () => {
    resetField("consentFile");
    onClose();
  };

  return (
    <Modal
      className="max-w-lg px-20 pb-10 pt-2"
      onClose={handleClose}
      show={show}
    >
      <form
        className="flex flex-col items-center"
        onSubmit={(e) => {
          void handleSubmit((data) => {
            uploadStudyConsentMutation(data, {
              onSuccess: () => {
                void pushToast({
                  type: "success",
                  title: "Consent uploaded",
                  message: `Research consent successfully uploaded!`,
                });
                handleClose();
              },
              onError: (err) => {
                handleAxiosFieldErrors(err, setError);
              },
            });
          })(e);
        }}
      >
        <ControlledDropzone
          accept={ACCEPT_IMAGE_FILE}
          control={control}
          name="consentFile"
          label="Select a file or drag and drop here"
          placeholder="PDF file, size no more than 10MB"
        />

        <div className="flex flex-col items-center gap-6">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={checkedConsent}
              onCheckedChange={() => setCheckedConsent((prev) => !prev)}
              aria-label="Select row"
              className="self-start"
            />
            <span className="text-xs text-gray-400">
              We confirm this consent form has received appropriate
              ethics/institutional approval.
            </span>
          </div>
          <div className="flex gap-2">
            <Button
              className="h-11 w-44 border-violet-900 text-violet-900"
              variant="outline"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              className="h-11 w-44"
              disabled={!checkedConsent}
              variant="primary"
              type="submit"
              isLoading={isLoading}
            >
              Upload
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
