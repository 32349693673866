import type { ColumnDef } from "@tanstack/react-table";

import type { Participant } from "@/api/api.types";

export const columns: ColumnDef<Participant>[] = [
  {
    id: "email",
    accessorFn: (participant) => participant.inviteeEmail,
    header: "Email",
  },
  {
    id: "researchStudies",

    header: "Invitation for the following research studies",
    cell: ({ row }) => {
      return (
        <span>{row.original.studiesNames ?? "No associated studies"}</span>
      );
    },
  },
];
