import { useState } from "react";
import type { RowSelectionState } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";

import { useParticipants } from "@/hooks/useParticipants";
import { useResearchStudies } from "@/hooks/useResearchStudies";
import { useUserStore } from "@/stores";
import { Button, icons } from "@/ui";
import { Tabs } from "@/ui/common/Tabs";
import { tw } from "@/utils";
import ActiveParticipantsTable from "./ActiveParticipantsTable/ActiveParticipantsTable";
import { ContentHeader } from "./ContentHeader";
import { InviteParticipantModal } from "./InviteParticipantModal";
import { ParticipantModal } from "./ParticipantModal";
import { PendingAcceptanceTable } from "./PendingAcceptanceTable/PendingAcceptanceTable";

const TABS = {
  activeParticipants: "Active participants",
  pendingAcceptance: "Pending acceptance",
} as const;

type Tab = (typeof TABS)[keyof typeof TABS];

export const Participants = () => {
  const [params, setParams] = useSearchParams();

  const { isAdmin } = useUserStore();

  const [activeTab, setActiveTab] = useState<Tab>(TABS.activeParticipants);

  const [isOpenDownloadScoresModal, setIsOpenDownloadScoresModal] =
    useState(false);
  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const [isOpenDeleteParticipantModal, setIsOpenDeleteParticipantModal] =
    useState(false);

  const [participantsRowSelectionState, setParticipantsRowSelectionState] =
    useState<RowSelectionState>({});

  const currentActivePage =
    Number(params.get("activePage")) > 0 ? Number(params.get("activePage")) : 1;

  const currentPendingPage =
    Number(params.get("pendingPage")) > 0
      ? Number(params.get("pendingPage"))
      : 1;

  const participantsCustomHook = useParticipants();

  const {
    data: activeParticipantsData,
    isLoading: isLoadingActiveParticipants,
  } = participantsCustomHook.useGetActiveParticipantsQuery(
    currentActivePage,
    params,
  );

  const {
    data: pendingParticipantsData,
    isLoading: isLoadingPendingParticipants,
  } = participantsCustomHook.useGetPendingParticipantsQuery(
    currentPendingPage,
    params,
  );

  const { data: researchStudyData, isLoading: isLoadingResearchStudy } =
    useResearchStudies().useGetMyResearchStudyQuery(!isAdmin());
  const researchStudy = researchStudyData?.data;

  const {
    mutate: sendParticipantsScoreMutation,
    isPending: isPendingSendParticipantsScoreMutation,
  } = participantsCustomHook.useSendParticipantsScoreMutation(() => {
    setIsOpenDownloadScoresModal(false);
  });

  const {
    mutate: deleteParticipantsFromStudiesMutation,
    isPending: isPendingDeleteParticipantsFromStudies,
  } = participantsCustomHook.useDeleteParticipantsFromStudiesMutation(() => {
    setIsOpenDeleteParticipantModal(false);
    setParticipantsRowSelectionState({});
  });

  const activeParticipants = activeParticipantsData?.data ?? [];
  const activeParticipantsPagination = activeParticipantsData?.pagination;

  const selectedParticipantsIndexes = Object.keys(
    participantsRowSelectionState,
  );

  const selectedParticipantsIds: number[] = selectedParticipantsIndexes
    .map((key) => activeParticipants[Number(key)]?.id)
    .filter((id): id is number => id !== undefined);

  const pendingParticipants = pendingParticipantsData?.data ?? [];

  const pendingParticipantsPagination = pendingParticipantsData?.pagination;

  const tabs = {
    [TABS.activeParticipants]: (
      <ActiveParticipantsTable
        isLoading={isLoadingActiveParticipants}
        pagination={{
          pageIndex: currentActivePage,
          pageSize: activeParticipantsPagination?.perPage ?? 0,
          totalPages: activeParticipantsPagination?.totalPages ?? 0,
          onPageChange: (page: number) => {
            setParams({ ...params, activePage: String(page) });
          },
        }}
        participants={activeParticipants}
        onRowSelectionChange={setParticipantsRowSelectionState}
        rowSelectionState={participantsRowSelectionState}
      />
    ),
    [TABS.pendingAcceptance]: (
      <PendingAcceptanceTable
        isLoading={isLoadingPendingParticipants}
        pagination={{
          pageIndex: currentPendingPage,
          pageSize: pendingParticipantsPagination?.perPage ?? 0,
          totalPages: pendingParticipantsPagination?.totalPages ?? 0,
          onPageChange: (page: number) =>
            setParams({ ...params, pendingPage: String(page) }),
        }}
        participants={pendingParticipants}
      />
    ),
  };

  return (
    <div className="flex h-full flex-1 flex-col">
      <InviteParticipantModal
        isOpen={isOpenInviteModal}
        onClose={() => setIsOpenInviteModal(false)}
      />
      <ContentHeader
        title="Participants"
        buttons={
          <>
            <Button
              className={tw(
                "h-10 w-40",
                (isAdmin() || researchStudy?.consentStatus === "uploaded") &&
                  "border border-violet-900 px-7 py-2 font-semibold text-violet-900",
              )}
              variant="outline"
              isLoading={isLoadingResearchStudy}
              disabled={
                !isAdmin() && researchStudy?.consentStatus !== "uploaded"
              }
              onClick={() => setIsOpenInviteModal((prev) => !prev)}
            >
              New Invite
            </Button>
            <Button
              className="h-10 w-40 px-2 py-0"
              variant="primary"
              isLoading={false}
              onClick={() => setIsOpenDownloadScoresModal(true)}
              disabled={activeParticipants.length == 0}
            >
              Download scores
            </Button>
          </>
        }
      />
      <div className="flex flex-1 flex-col gap-3">
        <div className="flex flex-row justify-between">
          <Tabs
            tabs={Object.values(TABS)}
            value={activeTab}
            onChange={(status) => {
              setActiveTab(status);
            }}
            renderTab={({ tab, onClick, selected }) => (
              <div key={tab}>
                <Button
                  variant="tertiary"
                  className={tw(
                    selected && " font-medium text-violet-800",
                    "px-2",
                  )}
                  onClick={onClick}
                >
                  {tab}
                </Button>
              </div>
            )}
          />
          {selectedParticipantsIds.length > 0 && (
            <Button
              variant="tertiary"
              className="flex text-sm font-normal text-red-700"
              onClick={() => setIsOpenDeleteParticipantModal(true)}
            >
              <icons.TrashIcon className="h-[18px] stroke-red-700" />
              <span className="text-red-700">Remove from researches</span>
            </Button>
          )}
        </div>
        {tabs[activeTab]}
        <ParticipantModal
          show={isOpenDownloadScoresModal}
          onSend={() => {
            sendParticipantsScoreMutation();
            setIsOpenDownloadScoresModal(false);
          }}
          isLoading={isPendingSendParticipantsScoreMutation}
          onClose={() => {
            setIsOpenDownloadScoresModal(false);
          }}
          title="Send Participant Scores"
          description={
            <div className="flex flex-col gap-5 text-center">
              <span>
                By clicking &quot;Send,&quot; you will receive a CSV document by
                email containing all participant scores.
              </span>
              <span>
                The document will include patient ID, age, sex, score and
                assessment date for each participant assessment.
              </span>
            </div>
          }
          primaryButtonText="Send"
        />
        <ParticipantModal
          show={isOpenDeleteParticipantModal}
          onSend={() => {
            deleteParticipantsFromStudiesMutation(selectedParticipantsIds);
            setIsOpenDeleteParticipantModal(false);
          }}
          isLoading={isPendingDeleteParticipantsFromStudies}
          onClose={() => {
            setIsOpenDeleteParticipantModal(false);
          }}
          title="Delete participant(s)?"
          description={
            <div className="flex flex-col gap-6 text-center">
              <span>
                Are you sure you want to permanently delete these participants
                from their research studies? This action cannot be undone.
              </span>
            </div>
          }
          primaryButtonText="Delete"
        />
      </div>
    </div>
  );
};
