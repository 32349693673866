import { Link, useLocation } from "react-router-dom";

import { tw } from "@/utils/tw";
import type { NavigationItem } from "./NavigationItems";

export const NavigationIcon = ({ item }: { item: NavigationItem }) => {
  const { pathname: currentPath } = useLocation();

  return (
    <Link to={item.path}>
      <div
        className={tw(
          "flex h-14 w-14 items-center justify-center",
          currentPath.startsWith(item.path) ? "bg-gray-100" : "",
        )}
      >
        {item.icon({
          className: currentPath.startsWith(item.path)
            ? "stroke-violet-950"
            : "stroke-gray-500",
        })}
      </div>
    </Link>
  );
};
