import { Outlet } from "react-router-dom";

import { Navbar } from "./Navbar/Navbar";
import { Navigation } from "./Navbar/Navigation";

export const Layout = () => {
  return (
    <div className="flex h-screen flex-row">
      <Navigation />
      <main className="flex-1 overflow-y-auto">
        <Navbar></Navbar>
        <div className="p-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
};
