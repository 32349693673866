import type { ReactNode } from "react";

import { Button, Modal } from "@/ui";

interface ParticipantModalProps {
  show: boolean;
  onClose: () => void;
  onSend: () => void;
  isLoading?: boolean;
  title: string;
  description: ReactNode;
  secondaryButtonText?: string;
  primaryButtonText?: string;
}

export const ParticipantModal = ({
  show,
  onClose,
  onSend,
  isLoading = false,
  title,
  description,
  secondaryButtonText = "Cancel",
  primaryButtonText = "Confirm",
}: ParticipantModalProps) => {
  return (
    <Modal
      title={title}
      description={description}
      onClose={onClose}
      show={show}
    >
      <div className="flex flex-row justify-center gap-2 px-6 pb-6 pt-2">
        <Button
          className="flex-1 border border-violet-900 px-2 py-2 font-semibold text-violet-900"
          variant="outline"
          isLoading={false}
          onClick={onClose}
        >
          {secondaryButtonText}
        </Button>
        <Button
          isLoading={isLoading}
          className="flex-1 px-2 py-0"
          variant="primary"
          onClick={onSend}
        >
          {primaryButtonText}
        </Button>
      </div>
    </Modal>
  );
};
