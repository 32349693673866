import { useParams } from "react-router-dom";

import { useResearchStudies } from "@/hooks/useResearchStudies";
import { Button, errorToast, Modal, useToastStore } from "@/ui";

interface DeleteResearchStudyModalProps {
  show: boolean;
  onClose: () => void;
}

export const DeleteResearchStudyModal = ({
  show,
  onClose,
}: DeleteResearchStudyModalProps) => {
  const { id } = useParams();
  const pushToast = useToastStore((state) => state.pushToast);
  const { mutate: deleteResearchMutation, isPending: isLoading } =
    useResearchStudies().deleteStudyMutation;

  return (
    <Modal
      title="Delete research"
      description={
        <span className="flex flex-col gap-5 text-center">
          Are you sure you want to delete this research study? This action will
          permanently remove all associated data and cannot be undone.
        </span>
      }
      onClose={onClose}
      show={show}
    >
      <div className="flex flex-row justify-center gap-2 px-6 pb-6">
        <Button
          className="flex-1 border border-violet-900 px-2 py-2  font-semibold text-violet-900"
          variant="outline"
          isLoading={false}
          onClick={onClose}
        >
          <span>Cancel</span>
        </Button>
        <Button
          className="flex-2 px-2 py-0"
          variant="primary"
          isLoading={isLoading}
          onClick={() => {
            if (id)
              deleteResearchMutation(id, {
                onSuccess: () => {
                  void pushToast({
                    type: "success",
                    title: "Success",
                    message: "Research study successfully deleted!",
                  });
                  onClose();
                },
                onError: (error: Error) => {
                  errorToast(error);
                },
              });
          }}
        >
          Delete research
        </Button>
      </div>
    </Modal>
  );
};
