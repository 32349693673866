import React from "react";

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <div className="flex h-screen-calculated w-full flex-col">
      <div className="relative flex h-72 flex-row items-center justify-center overflow-hidden lg:h-40">
        <img
          className="z-10 h-20 text-white"
          src="/images/goodhope-logo.svg"
          alt="This is GoodHope logo"
        />
        <div className="absolute -top-56 mx-auto flex h-[27rem] w-[230%] flex-row items-center justify-center rounded-b-half bg-violet-900 lg:h-96 lg:w-[150%]"></div>
      </div>

      {children}
    </div>
  );
};
