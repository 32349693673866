import type { Participant } from "@/api/api.types";
import type { Pagination } from "@/ui/common/TableComponent";
import TableComponent from "@/ui/common/TableComponent/TableComponent";
import { columns } from "./PendingAcceptanceColumns";

interface PendingAcceptanceTableProps {
  isLoading: boolean;
  participants: Participant[];
  pagination: Pagination;
}

export const PendingAcceptanceTable = ({
  isLoading,
  participants: participants,
  pagination,
}: PendingAcceptanceTableProps) => {
  return (
    <TableComponent
      isLoading={isLoading}
      data={participants}
      noDataMessage="No pending participants to show."
      columns={columns}
      pagination={pagination}
    />
  );
};
