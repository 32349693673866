import { useEffect } from "react";
import type {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  TableState,
} from "@tanstack/react-table";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { Pagination } from "@/ui/common/Pagination";
import { Table } from "@/ui/primitives";
import { tw } from "@/utils";
import TableBodyRenderer from "./TableBody";
import TableHeaderRenderer from "./TableComponentHeader";

export interface Pagination extends PaginationState {
  totalPages: number;
  onPageChange?: (page: number) => void;
}

interface TableComponentProps<T> {
  isLoading: boolean;
  data: T[];
  noDataMessage: string;
  columns: ColumnDef<T>[];
  pagination: Pagination;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  rowSelectionState?: RowSelectionState;
}

const TableComponent = <T extends { id: number }>({
  isLoading,
  data,
  noDataMessage,
  columns,
  pagination,
  onRowSelectionChange,
  rowSelectionState,
}: TableComponentProps<T>) => {
  const tableState: Partial<TableState> = {
    pagination: {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
    },
  };

  if (rowSelectionState !== undefined) {
    tableState.rowSelection = rowSelectionState;
  }

  const table = useReactTable({
    data,
    columns,
    pageCount: pagination.totalPages,
    state: tableState,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange,
  });

  useEffect(() => {
    onRowSelectionChange?.({});
  }, [pagination.pageIndex, onRowSelectionChange]);

  return (
    <div className="flex flex-1 flex-col gap-6">
      <Table className={tw("max-h-[548px]", data.length >= 8 && "h-full")}>
        <TableHeaderRenderer headerGroups={table.getHeaderGroups()} />
        <TableBodyRenderer
          isLoading={isLoading}
          columns={columns}
          table={table}
          noDataMessage={noDataMessage}
        />
      </Table>
      {pagination.totalPages > 1 && (
        <div className="flex w-full justify-center">
          <Pagination
            totalPages={table.getPageCount()}
            currentPage={table.getState().pagination.pageIndex}
            onPageChange={(page) => {
              if (pagination?.onPageChange) pagination.onPageChange(page);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TableComponent;
