export const ROUTES = {
  base: "/",
  verifyEmail: "/patient/email/verify/:id/:hash",
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/user/password/reset/:token",
  activateAccount: "/user/activate-account/:token",
  home: "/home",
  users: "/users",
  participants: "/participants",
  researchStudies: "/research-studies",
  patientResetPassword: "/patient/password/reset/:token",
  patientResetPasswordSuccess: "/patient/password/reset-success",
  notFound: "*",
} as const;

export const MODAL_ROUTES = {
  exampleModal: "/example-modal",
  userForm: "/user-form",
  deleteResearchModal: `${ROUTES.researchStudies}/delete-research`,
} as const;
