import type { ForwardedRef } from "react";
import type { DropzoneOptions, FileWithPath } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { Controller } from "react-hook-form";

import { SIZE } from "@/shared.constants";
import { bytesToMegabytes, forwardRef, tw } from "@/utils";
import { Button, icons, IconWrapper } from "../common";
import { Message } from "./Message";

interface DropzoneProps extends DropzoneOptions {
  id?: string;
  compact?: boolean;
  error?: string | boolean;
  label?: string;
  message?: string;
  placeholder?: string;
  value: FileWithPath | FileWithPath[] | null;
}

export const Dropzone = forwardRef(
  (props: DropzoneProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      value,
      id,
      compact,
      error,
      label = `Drop your files here`,
      message,
      multiple = false,
      placeholder = `Drag 'n' drop some files here, or click to select files`,
    } = props;
    const { getRootProps, getInputProps } = useDropzone({
      ...props,
      multiple,
    });

    const selectedFiles =
      (Array.isArray(value) ? value : value && [value]) ?? [];

    const files = selectedFiles.map((file: FileWithPath) => (
      <span key={file.path}>
        {file.path}
        {multiple && ` - ${bytesToMegabytes(file.size)} MB`}
      </span>
    ));

    const fileSize =
      !multiple &&
      selectedFiles[0]?.size &&
      bytesToMegabytes(selectedFiles[0].size);

    return (
      <div
        className={tw(
          "flex h-48 flex-col items-center gap-6 text-center",
          files.length > 0 && "h-60",
          !!error && "h-64",
        )}
      >
        <div
          {...getRootProps({ ref })}
          className={tw(
            "bg-salmon-01 focus:ring-nature-04 flex grow flex-col items-center gap-4 rounded-2xl  focus:outline-none ",
            Boolean(files.length) && "bg-salmon-02",
          )}
        >
          <div className="flex flex-col items-center gap-4">
            <IconWrapper size={SIZE.X_LARGE}>
              <icons.DragNDrop />
            </IconWrapper>
            <label htmlFor={id} className="text-sm">
              {files.length ? files : label}
            </label>
            <input {...getInputProps({ id })} />

            <p className="text-brown-06 text-xs  text-gray-400">
              {fileSize ?? placeholder}
            </p>
          </div>

          {files.length > 0 && (
            <IconWrapper
              size={SIZE.LARGE}
              className="border-salmon-06 bg-salmon-05 text-salmon-10 rounded-full border-2 p-1.5"
            >
              <icons.CheckIcon />
            </IconWrapper>
          )}

          <Button
            className="h-9 border-violet-900 text-sm text-violet-900"
            variant="outline"
          >
            Select file
          </Button>
          {(!compact || !!message || !!error) && (
            <Message message={message} error={error} />
          )}
        </div>
      </div>
    );
  },
);

interface ControlledDropzoneProps<TFieldValues extends FieldValues>
  extends Omit<DropzoneProps, "value"> {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
}
export const ControlledDropzone = <TFieldValues extends FieldValues>({
  name,
  control,
  multiple,
  ...props
}: ControlledDropzoneProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onChange, value, ref } = field;
        const { error } = fieldState;
        return (
          <Dropzone
            {...props}
            id={name}
            value={value}
            onDrop={(acceptedFiles) =>
              onChange(multiple ? acceptedFiles : acceptedFiles[0])
            }
            error={error?.message}
            ref={ref}
          />
        );
      }}
    />
  );
};
