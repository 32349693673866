import type { Study } from "@/api/api.types";
import type { Pagination } from "../../ui/common/TableComponent/TableComponent";
import TableComponent from "../../ui/common/TableComponent/TableComponent";
import { ResearchStudiesColumns } from "./ResearchStudiesColumns";

interface ResearchStudiesTableProps {
  isLoading: boolean;
  researchStudies: Study[];
  pagination: Pagination;
  isAdmin?: boolean;
}

const ResearchStudiesTable = ({
  isLoading,
  researchStudies,
  pagination,
  isAdmin = false,
}: ResearchStudiesTableProps) => {
  const columns = ResearchStudiesColumns(isAdmin);
  return (
    <TableComponent
      isLoading={isLoading}
      noDataMessage="No research studies to show."
      data={researchStudies}
      columns={columns}
      pagination={pagination}
    />
  );
};

export default ResearchStudiesTable;
