import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { login } from "@/api/login";
import { AuthLayout } from "@/layout/AuthLayout";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import { Button, errorToast } from "@/ui";
import H1 from "@/ui/form/H1";
import Input from "@/ui/form/Input";

const loginSchema = z.object({
  password: z.string(),
  email: z.string().email(),
});

type LoginForm = z.infer<typeof loginSchema>;

const useLogin = () => {
  return useMutation({
    mutationFn: login,
  });
};

export const Login = () => {
  const navigate = useNavigate();

  const { setToken, setRole } = useUserStore();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<LoginForm>({
    mode: "onBlur",
    resolver: zodResolver(loginSchema),
  });

  const onSubmit: SubmitHandler<LoginForm> = (data) => {
    loginAction(data, {
      onSuccess: (res) => {
        setToken(res.accessToken);
        setRole(res.role);
        navigate(ROUTES.researchStudies);
      },
      onError: (error) => {
        errorToast(error);
      },
    });
  };

  const { mutate: loginAction } = useLogin();

  return (
    <AuthLayout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between px-6 lg:w-96 lg:px-0"
      >
        <div className=" pb-6">
          <H1 className="pb-9 font-epilogue text-3xl font-bold text-violet-950">
            Welcome Back
          </H1>
          <div className="flex flex-col gap-6 pb-3">
            <Input
              label="Email Address"
              type="text"
              error={errors?.email?.message}
              id="email_login"
              className="w-full p-3 text-sm"
              placeholder="Enter your email address"
              {...register("email")}
            ></Input>

            <Input
              label="Password"
              type="password"
              error={errors?.password?.message}
              id="password_login"
              className="w-full p-3 text-sm"
              placeholder="Enter your password"
              {...register("password")}
            />
          </div>
          <a
            href={ROUTES.forgotPassword}
            className=" text-sm font-normal text-gray-500 underline decoration-gray-500 underline-offset-[3px]"
          >
            Forgot Password?
          </a>
        </div>

        <div className="flex flex-col pt-16">
          <Button
            className="w-full"
            type="submit"
            variant="primary"
            isLoading={false}
          >
            Log in
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
};
