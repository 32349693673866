import type { ColumnDef } from "@tanstack/react-table";

import type { Participant } from "@/api/api.types";
import { useUserStore } from "@/stores";
import { Checkbox } from "@/ui/form/Checkbox";

export const ActiveParticipantsColumns: () => ColumnDef<Participant>[] = () => {
  const { role } = useUserStore();

  const columns: ColumnDef<Participant>[] = [
    {
      id: "select",
      cell: ({ row }) =>
        row.original.studiesNames &&
        row.original.studiesNames.length > 0 && (
          <Checkbox
            id={row.id}
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
      enableHiding: false,
    },
    {
      id: "name",
      accessorFn: (participant) => participant.patient?.name,
      header: "Name",
    },
    {
      id: "email",
      accessorFn: (participant) => participant.patient?.email,
      header: "Email",
    },
    {
      id: "age",
      accessorFn: (participant) => participant.patient?.age,
      header: "Age",
    },
    {
      id: "sex",
      accessorFn: (participant) => participant.patient?.sex,
      header: "Sex",
    },
    {
      id: "country",
      accessorFn: (participant) => participant.patient?.country,
      header: "Country",
    },
  ];

  if (role === "admin") {
    columns.push({
      id: "research",
      accessorFn: (participant) =>
        participant.studiesNames && participant.studiesNames.length > 0
          ? participant.studiesNames
          : "No associated studies",
      header: "Associated research studies",
    });
  }

  return columns;
};
