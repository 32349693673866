import { MODAL_ROUTES } from "@/router/routes";
import { useNavigateModal } from "@/router/useNavigateModal";

export const DeleteResearchStudyButton = ({
  researchId,
}: {
  researchId: string;
}) => {
  const navigateModal = useNavigateModal();

  return (
    <button
      className="text-red-700"
      onClick={() => {
        navigateModal(`${MODAL_ROUTES.deleteResearchModal}/${researchId}`);
      }}
    >
      Delete research
    </button>
  );
};
