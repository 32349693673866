import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  createStudy,
  deleteResearchStudy,
  getMyResearchStudy,
  getResearchStudiesQuery as getResearchStudies,
  uploadResearchStudyConsent,
} from "@/api/researchStudies";
import { errorToast } from "@/ui";

const PAGE_SIZE = 8;

export const RESEARCH_STUDIES_QUERY_KEYS = {
  getResearchStudies: "getResearchStudies",
  getMyResearchStudy: "getMyResearchStudy",
  getResearchStudyConsent: "getResearchStudyConsent",
};

export const useResearchStudies = () => {
  const queryClient = useQueryClient();

  const useGetResearchStudiesQuery = (
    params?: URLSearchParams,
    enabled?: boolean,
    currentPage = 1,
    pageSize = -1,
  ) => {
    return useQuery({
      queryFn: () => getResearchStudies(currentPage, pageSize ?? PAGE_SIZE),
      queryKey: [
        RESEARCH_STUDIES_QUERY_KEYS.getResearchStudies,
        params && Object.fromEntries(params),
      ],
      enabled: enabled,
    });
  };

  const useGetMyResearchStudyQuery = (enabled = true) => {
    return useQuery({
      queryFn: () => getMyResearchStudy(),
      enabled: enabled,
      queryKey: [RESEARCH_STUDIES_QUERY_KEYS.getMyResearchStudy],
    });
  };

  const createResearchStudyMutation = useMutation({
    mutationFn: createStudy.mutation,
    onSuccess: () => {
      createStudy.invalidates(queryClient);
    },
    onError: (error: Error) => {
      errorToast(error);
    },
  });

  const deleteStudyMutation = useMutation({
    mutationFn: deleteResearchStudy.mutation,
    onSuccess: () => {
      deleteResearchStudy.invalidates(queryClient);
    },
  });

  const uploadResearchStudyConsentMutation = useMutation({
    mutationFn: uploadResearchStudyConsent.mutation,
    onSuccess: async () => {
      createStudy.invalidates(queryClient);
      await queryClient.invalidateQueries({
        queryKey: [RESEARCH_STUDIES_QUERY_KEYS.getMyResearchStudy],
      });
    },
    onError: (error: Error) => {
      errorToast(error);
    },
  });

  return {
    useGetResearchStudiesQuery,
    useGetMyResearchStudyQuery,
    createResearchStudyMutation,
    deleteStudyMutation,
    uploadResearchStudyConsentMutation,
  };
};
