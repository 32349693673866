import type { OnChangeFn, RowSelectionState } from "@tanstack/react-table";

import type { Participant } from "@/api/api.types";
import TableComponent from "@/ui/common/TableComponent/TableComponent";
import type { Pagination } from "@/ui/common/TableComponent/TableComponent";
import { ActiveParticipantsColumns } from "./ActiveParticipantsColumns";

interface ActiveParticipantsTableProps {
  isLoading: boolean;
  participants: Participant[];
  pagination: Pagination;
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
  rowSelectionState?: RowSelectionState;
}

const ActiveParticipantsTable = ({
  isLoading,
  participants,
  pagination,
  onRowSelectionChange,
  rowSelectionState,
}: ActiveParticipantsTableProps) => (
  <TableComponent
    isLoading={isLoading}
    noDataMessage="No active participants to show."
    data={participants}
    columns={ActiveParticipantsColumns()}
    pagination={pagination}
    onRowSelectionChange={onRowSelectionChange}
    rowSelectionState={rowSelectionState}
  />
);

export default ActiveParticipantsTable;
