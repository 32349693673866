import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MainLayout } from "@/layout/MainLayout";
import { ROUTES } from "@/router";
import { Button } from "@/ui";
import H1 from "@/ui/form/H1";

export const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const type = searchParams.get("type") ?? "webUser";

  const title =
    type == "webUserActivation"
      ? "Account setup confirmation"
      : "Reset successful";

  const descriptions = {
    webUser:
      "Your password has been reset successfully. You can now return to the login screen and access your account.",
    webUserActivation:
      "Your account has been successfully created. You can now access the research study dashboard.",
    patient: "You can now go back to the app and log in to your account.",
  } as Record<string, string>;

  return (
    <MainLayout>
      <div className="h-full w-full justify-center gap-48 pt-32 lg:flex lg:h-fit lg:flex-row lg:px-48">
        <div className="hidden xl:block">
          <img
            src="/images/reset-password-success-logo.svg"
            alt="This is a reset password success logo"
          />
        </div>

        <div className="flex h-[416px] flex-col justify-between px-6 lg:w-96 lg:px-0">
          <div className="flex flex-col justify-items-start">
            <CheckCircleIcon className="w-10 stroke-violet-800 stroke-[1.5px]"></CheckCircleIcon>
            <H1 className="pb-4 font-semibold">{title}</H1>
            <p className="max-w-[352px] text-base font-normal text-gray-500">
              {descriptions[type]}
            </p>
          </div>
          {type !== "patient" && (
            <Button
              onClick={() => {
                navigate(ROUTES.login);
              }}
              className="w-[345px]"
              type="submit"
              variant="primary"
            >
              Back to login
            </Button>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
