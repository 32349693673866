import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { UserRole } from "@/shared.types";

export interface User {
  email: string;
  name: string;
  picture: string;
}

export interface UserStoreState {
  user: User | null;
  token: string | null;
  role: UserRole | null;
  setUser: (user: User | null) => void;
  setRole: (role: UserRole | null) => void;
  setToken: (token: string | null) => void;
  logout: () => void;
  isAdmin: () => boolean;
}

export const useUserStore = create<UserStoreState>()(
  persist(
    (set, get) => ({
      user: null,
      token: null,
      role: null,
      setUser: (user: User | null) => {
        set(() => ({ user }));
      },
      setRole: (role: UserRole | null) => {
        set(() => ({ role }));
      },
      setToken: (token: string | null) => {
        set(() => ({ token }));
      },
      logout: () => {
        set({ user: null, token: null, role: null });
      },
      isAdmin: () => {
        const { role } = get();
        return role === "admin";
      },
    }),
    {
      name: "feedbackUserStore",
    },
  ),
);
