import { ROUTES } from "@/router/routes";
import { SIZE } from "@/shared.constants";
import { icons, IconWrapper } from "@/ui";

export interface IconProps {
  className?: string;
}

export interface NavigationItem {
  icon: (props: IconProps) => JSX.Element;
  path: string;
}

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    icon: (props: IconProps) => (
      <IconWrapper size={SIZE.X_LARGE}>
        <icons.Participants {...props} />
      </IconWrapper>
    ),
    path: ROUTES.participants,
  },
  {
    icon: (props: IconProps) => (
      <IconWrapper size={SIZE.X_LARGE}>
        <icons.Research {...props} />
      </IconWrapper>
    ),
    path: ROUTES.researchStudies,
  },
];
