import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useParticipants } from "@/hooks/useParticipants";
import { useResearchStudies } from "@/hooks/useResearchStudies";
import { useUserStore } from "@/stores";
import { Button, HookedSelect, Modal } from "@/ui";
import Input from "@/ui/form/Input";

interface InviteParticipantModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const INVITE_PARTICIPANT_MODAL_TEXTS = {
  title: "New Invite",
  description: (
    <div className="flex flex-col gap-6 text-center">
      <span>
        Enter the email address of the participant you wish to invite to
        download and use the app.
      </span>
      <span>
        The participant will receive an email with instructions on how to get
        started.
      </span>
    </div>
  ),
};

export const InviteParticipantModal = ({
  isOpen,
  onClose,
}: InviteParticipantModalProps) => {
  const { isAdmin } = useUserStore();
  const isAdminValue = isAdmin();

  const inviteParticipantAdminSchema = z.object({
    patientEmail: z
      .string()
      .min(1, { message: "Email is required" })
      .email({ message: "Invalid email" }),
    researchStudyId: z.number().optional().nullable(),
  });

  const inviteParticipantResearcherSchema = z.object({
    patientEmail: z
      .string()
      .min(1, { message: "Email is required" })
      .email({ message: "Invalid email" }),
  });

  type InviteParticipantAdminFormValues = z.infer<
    typeof inviteParticipantAdminSchema
  >;
  type InviteParticipantResearcherFormValues = z.infer<
    typeof inviteParticipantResearcherSchema
  >;

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    setError,
    reset,
    control,
  } = useForm<
    InviteParticipantAdminFormValues & InviteParticipantResearcherFormValues
  >({
    resolver: zodResolver(
      isAdminValue
        ? inviteParticipantAdminSchema
        : inviteParticipantResearcherSchema,
    ),
  });

  const onSuccess = () => {
    onClose();
    reset({
      patientEmail: "",
      researchStudyId: null,
    });
  };

  const {
    mutate: inviteParticipantMutation,
    isPending: isPendingInviteParticipantMutation,
  } = useParticipants().useInviteParticipantMutation(onSuccess, setError);

  const useResearchStudiesCustomHook = useResearchStudies();

  const { data } = useResearchStudiesCustomHook.useGetResearchStudiesQuery(
    undefined,
    isAdminValue && isOpen,
  );

  const researchStudies = data?.data ?? [];
  const researchStudiesOptions = researchStudies
    .filter((researchStudy) => researchStudy.consentStatus === "uploaded")
    .map((researchStudy) => ({
      value: researchStudy.id,
      label: researchStudy.name,
    }));

  return (
    <Modal
      show={isOpen}
      title={INVITE_PARTICIPANT_MODAL_TEXTS.title}
      description={INVITE_PARTICIPANT_MODAL_TEXTS.description}
      onClose={onClose}
      className="py-10"
    >
      <form
        onSubmit={(e) => {
          void handleSubmit((value) => {
            inviteParticipantMutation({
              patientEmail: value.patientEmail,
              researchStudyId: value.researchStudyId ?? undefined,
            });
          })(e);
        }}
        className="flex flex-col gap-7"
      >
        <div className="flex flex-col gap-7">
          <div className="flex flex-col gap-7">
            <Input
              id="patientEmail"
              label="Invite email"
              placeholder="Input email here"
              {...register("patientEmail")}
              error={errors.patientEmail?.message}
            />
            {isAdminValue && (
              <HookedSelect
                label="Research study"
                control={control}
                name="researchStudyId"
                id="researchStudyId"
                options={researchStudiesOptions}
                placeholder="Select a research study"
                error={errors.researchStudyId?.message}
              />
            )}
          </div>

          <div className="flex justify-between">
            <Button
              onClick={onClose}
              variant="outline"
              className="h-12 w-40 border border-violet-900 px-7 py-2 font-semibold text-violet-900"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!isDirty || isPendingInviteParticipantMutation}
              className="h-12 w-40"
              isLoading={isPendingInviteParticipantMutation}
            >
              Send invite
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
