import type { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";

import type { UserRole } from "@/shared.types";
import { useUserStore } from "@/stores/useUserStore";
import { ROUTES } from "./routes";

type UserRoleState = UserRole | "loggedOut";

const HOME = {
  loggedOut: ROUTES.login,
  admin: ROUTES.participants,
  researcher: ROUTES.participants,
} as const;

export const ProtectedRoute = ({
  children,
  expected,
}: {
  children?: ReactNode;
  expected: UserRoleState | UserRoleState[];
}) => {
  const userRoleState = useUserStore((state) =>
    state.role ? state.role : "loggedOut",
  );

  if (!expected.includes(userRoleState)) {
    return <Navigate to={HOME[userRoleState]} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
