import type { SVGProps } from "@/shared.types";

export const NavbarRightAsset = ({ className, ...props }: SVGProps) => {
  return (
    <svg
      width="126"
      height="140"
      viewBox="0 0 126 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g clipPath="url(#clip0_2643_1108)">
        <path
          d="M48.1582 14.271C48.1582 14.271 75.2184 23.7426 93.0699 27.571C114.005 32.0605 147.412 34.1802 147.412 34.1802C121.423 39.3 107.175 43.563 81.3881 48.6654M81.3881 48.6654C81.1857 48.7056 80.9829 48.7456 80.7791 48.7857M81.3881 48.6654L80.7791 48.7857M168.361 125.73C168.361 125.73 119.762 123.38 87.5815 120.018C55.4015 116.657 3.55579 108.513 3.55579 108.513L162.254 85.5035C162.254 85.5035 116.441 82.0879 87.5815 77.1808C58.722 72.2737 15.692 60.6986 15.692 60.6986C41.4223 55.6298 55.0289 53.8583 80.7791 48.7857"
          stroke="#370880"
          strokeWidth="31.7675"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2643_1108">
          <rect width="171.779" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
