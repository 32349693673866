import type {
  PaginatedResponse,
  Participant,
  ServiceResponse,
} from "./api.types";
import { privateAPI } from "./axios";

export const getParticipants = async (
  page?: number,
  pageSize?: number,
  status?: string,
) => {
  const response = await privateAPI.get<PaginatedResponse<Participant>>(
    "/users/participants",
    { params: { page: page, pageSize: pageSize, status: status } },
  );

  return response.data;
};

export const inviteParticipant = {
  mutation: async ({
    patientEmail,
    researchStudyId,
  }: {
    patientEmail: string;
    researchStudyId?: number;
  }) => {
    const response = await privateAPI.post<ServiceResponse<null>>(
      "users/invite-patient",
      {
        patientEmail,
        researchStudyId,
      },
    );

    return response.data.data;
  },
};

export const sendParticipantsScore = {
  mutation: async () => {
    const response = await privateAPI.get<ServiceResponse<null>>(
      "users/participants/scores",
    );

    return response.data.data;
  },
};

export const deleteParticipantsFromStudies = {
  mutation: async (participantsIds: number[]) => {
    const response = await privateAPI.post<ServiceResponse<null>>(
      "users/participants/delete-from-studies",
      {
        participantsIds,
      },
    );

    return response.data.data;
  },
};
