import { SignOut } from "phosphor-react";

import { useUserStore } from "@/stores/useUserStore";
import { NavigationIcon } from "./NavigationIcon";
import { NAVIGATION_ITEMS } from "./NavigationItems";

export const Navigation = () => {
  const logout = useUserStore((s) => s.logout);

  return (
    <div className="w-200 flex flex-col items-center justify-between border  border-gray-300  bg-white px-4 pb-6 pt-4">
      <div className="flex flex-col gap-11">
        <img
          className="size-12"
          src="/images/goodhope-icon.svg"
          alt="This is GoodHope logo"
        />
        <ul className="flex flex-col gap-6">
          {NAVIGATION_ITEMS.map((item) => (
            <NavigationIcon key={item.path} item={item} />
          ))}
        </ul>
      </div>
      <button onClick={logout}>
        <SignOut size={32} color="#6B7280" />
      </button>
    </div>
  );
};
