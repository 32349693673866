import type { ComponentPropsWithoutRef } from "react";
import { useRef, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { Command } from "cmdk";
import { Controller } from "react-hook-form";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

import { tw } from "@/utils";
import { icons, IconWrapper } from "../common";
import type {
  BaseFieldProps,
  MultiSelectProps,
  Option,
  SingleSelectProps,
} from "./form.types";
import { Label } from "./Label";

type BaseProps = Omit<ComponentPropsWithoutRef<"input">, "value" | "onChange"> &
  BaseFieldProps;

interface CommonProps<TValue> extends BaseProps {
  options: readonly Option<TValue>[];
  autocomplete?: boolean;
  emptyMessage?: string;
  searchPlaceholder?: string;
}

type SelectProps<TValue> = CommonProps<TValue> &
  (SingleSelectProps<TValue> | MultiSelectProps<TValue>);

export const Select = <TValue extends string | number>({
  autocomplete,
  className,
  containerClassName,
  disabled,
  emptyMessage,
  error,
  id,
  label,
  left,
  multiple,
  options,
  placeholder,
  searchPlaceholder,
  value,
  onChange,
  ...rest
}: SelectProps<TValue>) => {
  const [open, setOpen] = useState(false);

  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleSelect = (selectedValue: TValue) => {
    if (multiple) {
      const selectedValues = value.some((v) => v === selectedValue)
        ? value.filter((v) => v !== selectedValue)
        : [...value, selectedValue];

      onChange(selectedValues);
    } else {
      onChange(selectedValue);
      setOpen(false);
    }
  };

  const selectedOption = !multiple
    ? options.find((option) => option.value === value)
    : undefined;

  return (
    <div className={tw("flex flex-col gap-1.5", containerClassName)}>
      {label && (
        <Label
          htmlFor={id}
          onClick={(e) => {
            e.preventDefault();
            triggerRef.current?.focus();
          }}
          label={label}
        />
      )}

      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger
          id={id}
          ref={triggerRef}
          className={tw(
            "bg-salmon-01 text-brown-09 placeholder:text-brown-06 focus:border-brown-08 focus:ring-salmon-03 disabled:border-brown-02 disabled:bg-brown-02 flex h-12 w-full items-center gap-2 rounded-xl border border-gray-300 px-4 py-3 text-sm focus:outline-none focus:ring-1",
            error && "border-error-600",
            className,
          )}
          disabled={disabled}
        >
          {left && (
            <div className="pointer-events-none">
              <IconWrapper
                size="sm"
                className={tw("fill-brown-06", !!error && "fill-red-03")}
              >
                {left}
              </IconWrapper>
            </div>
          )}
          <div className="flex min-w-0 grow items-center gap-3 text-left">
            {!multiple && selectedOption ? (
              <span className="truncate">{selectedOption.label}</span>
            ) : (
              <span className="truncate font-medium text-gray-400">
                {placeholder ?? "Select..."}
              </span>
            )}
          </div>

          <IconWrapper
            size="sm"
            className={tw(
              "fill-brown-09 stroke-brown-09 cursor-pointer duration-150",
              open && "rotate-180",
              disabled && "cursor-not-allowed",
            )}
          >
            <icons.ChevronDownIcon />
          </IconWrapper>
        </Popover.Trigger>
        <Popover.Content
          sideOffset={8}
          className="z-50 min-w-[var(--radix-popover-trigger-width)] overflow-y-auto rounded-md border bg-white shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        >
          <Command className="relative flex h-full w-full flex-col rounded-md">
            {autocomplete && (
              <div className="bg-salmon-01 sticky top-0 z-10 border-b p-2">
                <Command.Input
                  className="focus:ring-salmon-03 flex w-full rounded-md bg-transparent px-3 py-2 text-sm focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-50"
                  placeholder={searchPlaceholder ?? "Search..."}
                  {...rest}
                />
              </div>
            )}
            <div className="h-auto text-sm first:border-t-0">
              {!!options.length && (
                <Command.Empty className="py-6 text-center">
                  {emptyMessage ? emptyMessage : "No option found."}
                </Command.Empty>
              )}

              <Command.List className="bg-white">
                <Command.Group className="max-h-48 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-gray-700">
                  {!options.length &&
                    (emptyMessage ? (
                      emptyMessage
                    ) : (
                      <div className="py-6 text-center" role="presentation">
                        No options available
                      </div>
                    ))}
                  {options.map((option) => (
                    <Command.Item
                      key={option.value}
                      value={option.label}
                      onSelect={() => handleSelect(option.value)}
                      disabled={option.disabled}
                      className="aria-selected:text-brown-10 flex cursor-pointer select-none items-center justify-between gap-2 truncate px-4 py-3 outline-none aria-disabled:pointer-events-none aria-disabled:opacity-50 aria-selected:bg-gray-50 [&:last-child]:rounded-md [&:not(:last-child)]:border-b"
                    >
                      {multiple && (
                        <input
                          id={`multi-select-option-${option.label}`}
                          tabIndex={-1}
                          readOnly
                          type="checkbox"
                          checked={value.includes(option.value)}
                          className="checked:bg-nostalgia-purple-900 hover:checked:bg-nostalgia-purple-700 focus:ring-nostalgia-purple-900 focus:checked:bg-nostalgia-purple-900 cursor-pointer rounded"
                        />
                      )}

                      <div className="flex grow items-center gap-3">
                        {option.label}
                      </div>

                      {!multiple && value === option.value && (
                        <icons.Check
                          className="text-nostalgia-purple-700 h-4 w-4 stroke-2"
                          aria-hidden="true"
                        />
                      )}
                    </Command.Item>
                  ))}
                </Command.Group>
              </Command.List>
            </div>
          </Command>
        </Popover.Content>
      </Popover.Root>

      {error && (
        <span
          id={`${id}_error`}
          className="text-left text-sm font-medium text-error-600"
        >
          {error}
        </span>
      )}
    </div>
  );
};

interface HookedSelectProps<TFieldValues extends FieldValues>
  extends Omit<SelectProps<string | number | boolean>, "onChange" | "value"> {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
}
export const HookedSelect = <TFieldValues extends FieldValues>({
  name,
  control,
  ...props
}: HookedSelectProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange, value } = field;
        return <Select {...props} value={value} onChange={onChange} />;
      }}
    />
  );
};
